<template>
  <div class="rows books">
    <div class="card mt-2 pt-2">
      <h2 class="title h2 text-center"> Detail Subledger {{ this.$route.query.offer_type}}</h2>
      <div class="card-body">
        <div class="row" v-if="offer_type =='single' || offer_type =='subscription'">
           <div  class="col-md-4" v-for="(data, no ) in aggregate" :key="no">
            <b-card :no-body="true" :key="no" class="shadow-lg">
              <b-card-body class="p-0 clearfix">
                <i
                  class="fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left"
                ></i>
                <div v-if="aggregate" class="h5 text-uppercase mb-0 pt-3">
                  <label for="" v-if="data.client_id ==1">
                    Ebooks
                  </label>
                  <label for="" v-if="data.client_id ==82">
                    Gramedia.com
                  </label>
                  <label for="" v-if="data.client_id ==67">
                    E-perpus
                  </label>
                </div>
                <div v-else class="h6 text-info mb-0 pt-3">
                  <b-spinner type="grow" label="Loading..."></b-spinner>
                </div>

                <div v-if="aggregate" class="text-muted text-uppercase font-md">
                  {{ formatPrice(data.amount,"IDR")}} / ({{data.total}})
                </div>
                <div v-else class="h6 text-info mb-0 pt-3">
                  <b-spinner type="grow" label="Loading..."></b-spinner>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>
        <div class="col-md-12">
          <b-row>
            <div class="col-auto">Revenue</div>
            <div class="col-auto">
              <strong>
                {{ formatPrice(amount,"IDR")}}
              </strong>
            </div>
            <div class="col-auto">
              <i class="fa fa-refresh" @click="actionRefresh()"></i>
            </div>
            <div class="col text-right" v-if="offer_type =='buffet'">
              <b-button size="sm" variant="primary" @click="onDownload" :disabled="isDownloadingBuffet">
                <b-spinner small class="align-middle" v-if="isDownloadingBuffet">
                </b-spinner>
                <em class="fa fa-download" v-else></em>
              </b-button>
            </div>
          </b-row>
          <TableSubledger v-if="offer_type =='buffet'"
            :items="items" 
            :totalRows="parseInt(totalRows)" 
            :isLoading="isLoading" 
            :perPage="perPage"
            :currentPage="currentPage"
            />
          <TableSingle v-else-if="'single' || offer_type =='subscription' || offer_type =='buffet bundling' || offer_type =='bundle'"
            :items="items" 
            :totalRows="parseInt(totalRows)" 
            :isLoading="isLoading"
            :perPage="perPage"
            :currentPage="currentPage" 
          />
           <b-row class="justify-content-start mt-2 pt-2">
            <b-col lg="3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              />
            </b-col>
          </b-row>
        </div>
        <div class="col-md-12 mt-1" v-if="offer_type =='buffet'">
          <h3> 5 Popular Reading Premium</h3> 

          <div class="row">
            <div class="col-md-2" v-for="popular in populars" :key="popular.id">
              <div class="cards">
                 <img 
                  class="img-thumbnail cover" 
                  :src="basePath + removeBucket(popular.thumb_image_normal)" 
                  alt="Card image cap">
                <div class="row">

                
                <p class="card-text">{{ popular.item_name ? popular.item_name.substr(0,100): '' }}</p>
               
                <div class="card-text">
                  <span> Read by {{ popular.people }} User </span>
                  <br>
                  of {{ formatSeconToDays(popular.duration) }}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState,mapGetters, mapActions} from 'vuex'
import TableSubledger from "../../components/subledger/table.vue"
import TableSingle from "../../components/subledger/table-single.vue"
import moment from "moment";
  export default {
    name: 'SubledgerVendor',
    components: {
      TableSubledger,
      TableSingle,
    },
    data () {
      return {
        Dates: {
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        },
        range: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
        masks: {
          input: "YYYY-MM-DD",
        },
        currentPage: 1,
        perPage: 100,
        basePath:'https://ebooks.gramedia.com/ebook-covers/',
        offer_type: '',
        isDownloadingBuffet: false
      }
    },
    computed: {
      ...mapGetters('subledger',['amount']),
      ...mapState({
        items: (state) => state.subledger.item_wallet,
        isLoading: (state) => state.subledger.isLoading,
        totalRows: (state) => state.subledger.totalWallet,
        amount: (state) => state.subledger.totalWalletAmount,
        populars: (state) => state.subledger.populars,
        aggregate: (state) => state.finance.aggregates,
      })
    },
    watch: {
      currentPage: function() {
        this.actionLoadpage()
      }
    },
    mounted() {
      this.actionSearch()
      this.offer_type = this.$route.query.offer_type
    },
    methods: {
      //
      ...mapActions('subledger',['fetchReportByVendorOffers']),
      ...mapActions('finance',['fetchReportDetailGroupByClient', 'sendReportDetailBuffetByPeriod']),
      actionLoadpage(){
        let period = this.$route.query.period
        let payload = {
          period: moment(period).format("YYYY-MM-DD"),
          id: this.$route.params.vendorid,
          offer_type: this.$route.query.offer_type,
          limit: this.perPage,
          page : this.currentPage
        };
        this.fetchReportByVendorOffers(payload)
      },
      actionRefresh(){
        this.actionSearch()
      },
      actionSearch(){
        let period = this.$route.query.period
        let payload = {
          period: moment(period).format("YYYY-MM-DD"),
          id: this.$route.params.vendorid,
          offer_type: this.$route.query.offer_type,
          limit: this.perPage,
          page : this.currentPage
        };
        
        this.fetchReportByVendorOffers(payload)
        if(this.offer_type != 'buffet'){
          this.fetchReportDetailGroupByClient({
            vendor_id: this.$route.params.vendorid,
            period: moment(period).format("YYYY-MM-DD"),
            offer_type: this.$route.query.offer_type,
          })
        }
      },
      formatPrice(value, currency) {
        if (value) {
          let formatter = null;
          if (currency == "USD") {
            formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            });
          } else {
            formatter = new Intl.NumberFormat("en-ID", {
              style: "currency",
              currency: currency,
            });
          }

          return formatter.format(value);
        }

        return "0";
      },
      removeBucket(str) {
        console.log(`original ${str}`);
        if (str.substring(0, 6) == "images") {
          return str.substring(9);
        } else {
          return str;
        }
      },
      formatSeconToDays(duration){
        let days = Math.floor(duration / (24 * 60 * 60));
        let hours = Math.floor((duration - days * 24 * 60 * 60) / (60 * 60));
        let minutes = Math.floor((duration - days * 24 * 60 * 60 - hours * 60 * 60) / 60);
        let seconds = Math.floor(duration - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60);
        return `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`
      },
      async onDownload() {
        this.isDownloadingBuffet = true;
        const response = await this.sendReportDetailBuffetByPeriod({
          period: this.$route.query.period,
          vendor_id: this.$route.params.vendorid,
        });

        if (response?.status == 200) {
          const emailAccepted = (response.data?.data?.accepted ?? []).join(', ');
          let message = 'Report successfully sent to this email ';
          message += `
          <br>
          <small>
            (${emailAccepted})
          </small>
          `;
          this.$swal('Success Downloaded', message, 'success')
          this.isDownloadingBuffet = false;
        } else {
          let message = `please contact your administrator with this code (${response?.status})`;
          this.$swal('Download Fail!!', message, 'error')
          this.isDownloadingBuffet = false;
        }
      }
    }
  }
</script>
<style scoped>
.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
.cover {
  height: auto;
  width: 180px;
  padding:10px;
  margin: 5px 10px;
}
.center {
  width:90%;
  margin-left: auto;
  margin-right: auto;
}
</style>